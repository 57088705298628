import React from 'react';

interface PageHeaderProps {
    title: string;
    subtitle: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, subtitle }) => {
    return (
        <div>
            <div className="relative flex flex-col h-full mt-20" >
                {/* TITLE Section */}
                <div className="h-screen flex flex-col z-40 items-center text-center">
                    {/* Your content here */}
                    <h1 className="opacity-0 animate-fadeIn delay-2 md:w-4/6 pb-5 mx-12 mt-24 2xl:text-7xl xl:text-7xl lg:text-7xl text-4xl leading-120 tracking-tighter text-center uppercase  text-white dark:text-white">
                        {title}
                    </h1>
                    <div className="opacity-0 animate-fadeIn delay-3 uppercase 2xl:text-2xl xl:text-2xl lg:text-2xl text-1xl text-white leading-34 tracking-tight text-center max-w-[700px]">
                        {subtitle}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageHeader;
