import Slider from 'react-slick';
import "./ImageSlider.css";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const settings = {
    dots: false,
    arrows: true, 
    infinite: true,
    speed: 500,
    slidesToShow:2,
    slidesToScroll: 1,
    swipeToSlide: true,
    centerMode: true,
};

const SignageSlider = () => (
    <Slider {...settings} className="flex ">
        {/*<img src="/products/product-sara-0.png" alt="" className=" h-[500px]  object-contain " />
        <img src="/products/product-sara-1.png" alt="" className=" h-[500px] object-contain " />
        <img src="/products/product-sara-2.png" alt="" className=" h-[500px] object-contain " />*/}
        <img src="/projects/MUSEO%20EGIZIO%20PORTALE/411069_10152101147260103_1470164409_o.jpg" alt="" className=" h-[500px] object-contain " />
        <img src="/projects/RIYADH/img_1.png" alt="" className="h-[500px] object-contain " />
        <img src="/projects/MUSEO DI ANTICHITA'/Discariche2.JPG" alt="" className="h-[500px] object-contain " />
        <img src="/projects/QUE BELLO ES VIVIR/WhatsApp Image 2018-10-15 at 12.10.34(1).jpeg" alt="" className=" h-[500px] object-contain " />
        <img src="/projects/ARTEMIDORO/IMG_1239.JPG" alt="" className=" h-[500px] object-contain " />
        <img src="/projects/MARTINI RED CIRCLE/CasaMartini_7.jpg" alt="" className=" h-[500px] object-contain " />
        <img src="/projects/MUSEO EGIZIO IL DONO DI THOT/thot.jpg" alt="" className=" h-[500px] object-contain " />
        <img src="/projects/MARTINI/Martini-020.jpg" alt="" className=" h-[500px] object-contain " />
        <img src="/projects/MUSEO EGIZIO GALLERIA DELLA SCRITTURA/torino_egizio_galleria-della-scruttura_sala-papiri_foto-graziano-tavan.jpg" alt="" className=" h-[500px] object-contain " />
        <img src="/projects/MUSEO DI ANTICHITA'/Personaggio 2.JPG" alt="" className=" h-[500px] object-contain " />
        <img src="/projects/MUSEO EGIZIO IL DONO DI THOT/thot-1.jpg" alt="" className=" h-[500px] object-contain " />


    </Slider>
);





export default SignageSlider;